import Rails from "@rails/ujs"
Rails.start()

import * as Turbo from "@hotwired/turbo"
require("channels")

import "controllers"

import 'regenerator-runtime/runtime';
import 'core-js/features/array/includes'
import 'core-js/features/array/from'
import 'core-js/features/array/find'
import 'core-js/features/symbol/is-concat-spreadable'
import 'core-js/features/array/virtual/find-index'
import 'core-js/features/symbol'

import '../ie11_polyfills.js'

import * as L from "leaflet"
import tippy, { followCursor } from 'tippy.js'
import Kuki from 'kuki.js'
import Sortable from 'sortablejs'
import AOS from 'aos'
import 'lazysizes'

import { initLightbox } from '../lightbox'
import initializeMap, { getPositionSuccess, getPositionFailure } from '../map'
import initializeGeolocation from '../geolocation'
import initUppy from '../uppy'
import initCountrySelects from '../country_selects'
import initTopsoilColors from '../topsoil_colors'
import initModals from '../modals'
import initSmoothScroll from '../smooth_scroll'
import initChoices from '../choices_select'
import initNavbar from '../navbar'
import initLocationAutocomplete from '../init_location_autocomplete'
import initFoldOutMenu from '../fold_out_menu'
import initImageDescriptionHint from '../image_description_hint'
import initSoilTypeTextField from '../soil_type_text_field'
import initFlatpickrMonthSelect from '../flatpickr_month'
import initClipboard from '../init_clipboard'
import initTutorialCarousel from '../tutorial_carousel'
import initVideo from '../video'
import '../modernizr.js'

import 'fg-loadcss'

document.addEventListener("turbo:load", () => {
  // bugfix? should fix invalid csrf token
  // https://github.com/rails/rails/issues/24257#issuecomment-705373212
  Rails.refreshCSRFTokens()

  // landing-page video
  initVideo()

  // submit form on change
  const submitOnChangeElements = document.querySelectorAll("[data-submit-on-change='true']")
  if(submitOnChangeElements.length > 0) {
    submitOnChangeElements.forEach((element) => {
      element.addEventListener("change", (e) => {
        const form = e.target.form
        form.submit()
      })
    })
  }
  // init AOS
  AOS.init()

  // scroll to last message in conversations view
  const conversationContainer = document.querySelector('.conversations__messages .body');

  if (conversationContainer) {
    conversationContainer.scroll(0, conversationContainer.scrollHeight)
  }

  // tutorial-carousel
  initTutorialCarousel()

  // preview profile-picture before upload
  const profile_picture_input = document.querySelector("#user_profile_picture")

  if (profile_picture_input != null) {
    profile_picture_input.addEventListener('change', (e) => {
      const reader = new FileReader();
      if (event.target.files[0] == undefined) {
        return false
      }

      reader.onload = function() {
        var output = document.querySelector('.profile-picture-preview');
        output.src = reader.result;
        output.classList.remove('is-hidden')

        output.onload = function() {
          var imageWidth = output.naturalWidth
          var imageHeight = output.naturalHeight
          var picturePreview = document.querySelector('[data-element="profile-picture"]')

          picturePreview.classList.remove('landscape', 'portrait', 'square')
          if (imageWidth > imageHeight) {
            picturePreview.classList.add('landscape')
          } else if (imageWidth < imageHeight) {
            picturePreview.classList.add('portrait')
          } else {
            picturePreview.classList.add('square')
          }
        }
      }

      reader.readAsDataURL(event.target.files[0]);
    })
  }

  // remove loading-indicator as soon as images get loaded
  const images_with_loadingindicator = document.querySelectorAll(".has-loading-indicator")
  images_with_loadingindicator.forEach(function(wrapper, index) {
    const image = wrapper.querySelector("img")

    if(image == undefined) return false

    image.addEventListener("load", (e) => {
      wrapper.classList.remove("has-loading-indicator")
    })
  })

  // sortable js collections
  const collection_wrapper = document.querySelector("#collection-sort-wrapper")

  if (collection_wrapper) {
    const sort_url = collection_wrapper.dataset.sortUrl
    const csrf_token = document.querySelector("[name='csrf-token']").getAttribute("content")

    const sortable = new Sortable(collection_wrapper, {
      draggable: '.collection-draggable-item',
      ghostClass: 'has-dragndrop-ghost',
      delay: 175,
      onUpdate: (event) => {
        const dragged_item = event.item;
        const collection_id = dragged_item.dataset.collectionId
        const old_index = event.oldIndex;
        const new_index = event.newIndex;

        const response = fetch(sort_url, {
          method: 'put',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf_token,
          },
          body: JSON.stringify({
            old_index: old_index,
            new_index: new_index,
            id: collection_id,
          }),
        }).then(response => {
          if(!response.ok) {
            throw new Error("Sort-Request error")
          }
          return response.json()
        }).then(json => {
        }).catch(error => {
          console.log(error)
        })

      },
    })
  }

  // sortable js collections#show
  const collection_list_wrapper = document.querySelector("#collection-show-sort-wrapper")

  if (collection_list_wrapper) {
    const sort_url = collection_list_wrapper.dataset.sortUrl
    const csrf_token = document.querySelector("[name='csrf-token']").getAttribute("content")

    const sortable = new Sortable(collection_list_wrapper, {
      draggable: '.collection-draggable-item',
      ghostClass: 'has-dragndrop-ghost',
      onUpdate: (event) => {
        const dragged_item = event.item;
        const assignment_id = dragged_item.dataset.assignmentId
        const old_index = event.oldIndex;
        const new_index = event.newIndex;

        const response = fetch(sort_url, {
          method: 'put',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf_token,
          },
          body: JSON.stringify({
            old_index: old_index,
            new_index: new_index,
            assignment_id: assignment_id,
          }),
        }).then(response => {
          if(!response.ok) {
            throw new Error("Sort-Request error")
          }
          return response.json()
        }).then(json => {
        }).catch(error => {
          console.log(error)
        })

      },
    })
  }


  // kuki
  Kuki.initialize({ language: document.querySelector("body").dataset.language, cookieTypes: ['marketing'] })

  const kukiButton = document.querySelector("#kuki-open")
  if (kukiButton != undefined) {
    kukiButton.addEventListener("click", (e) => {
      e.preventDefault()
      window.Kuki.open()
    })
  }

  const loadAssets = (type) => {
    const assets = document.querySelectorAll("[data-kuki='" + type + "']")
    for(let i = 0; i < assets.length; i++) {
      const asset = assets[i]

      const assetUrl = asset.dataset.kukiSrc
      asset.setAttribute('src', assetUrl)
      window['ga-disable-160175242-1'] = false;

      if (typeof gtag != 'function') { return; }

      return gtag('config', 'UA-160175242-1', { page_path: window.location.href });
    }
  }

  const unloadAssets = (type) => {
    const assets = document.querySelectorAll("[data-kuki='" + type + "']")
    for(let i = 0; i < assets.length; i++) {
      const asset = assets[i]

      const assetUrl = asset.getAttribute('src')
      asset.setAttribute('src', '')
      asset.dataset.kukiSrc = assetUrl
      window['ga-disable-160175242-1'] = true;
    }
  }

  Kuki.onConsentChange('marketing', (event) => {
    if (event.newValue == true) {
      loadAssets('marketing')
    } else {
      unloadAssets('marketing')
    }
  })

  if(Kuki.consentGivenFor('marketing') == true) {
    loadAssets('marketing')
  }

  initClipboard()
  initCountrySelects()
  initSmoothScroll()
  initChoices()
  initNavbar()
  initUppy("uppy")
  initTopsoilColors()
  initLightbox()
  initModals()
  tippy('[data-tippy-content]:not([data-controller])', { followCursor: false, plugins: [followCursor], touch: false })
  initFoldOutMenu()
  initImageDescriptionHint()
  initSoilTypeTextField()
  initFlatpickrMonthSelect()
  // map
  initializeMap("#collection-map-container")
  initializeMap("#map-container")
  const edit_map = initializeMap("#edit-map-container")

  initializeGeolocation("[data-js-id='get-current-position']", (position) => {
    getPositionSuccess(edit_map, position)
  }, (error) => {
    getPositionFailure(edit_map, error)
  })

  initLocationAutocomplete(edit_map)

  // update file-inputs with filename
    const file_inputs = document.querySelectorAll('input[type=file]');

    for(let i = 0; i < file_inputs.length; i++) {
      const file_input = file_inputs[i]

      file_input.onchange = () => {
        if (file_input.files.length > 0) {
          const file_name = file_input.parentElement.querySelector('.file-name')
          file_name.textContent = file_input.files[0].name;
        }
      }
    }
})
